import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "../assets/css/home-slider.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import img1 from "../assets/img/perfume_slider_02.webp";
import img2 from "../assets/img/banner_06.webp";
import img4 from "../assets/img/perfume_slider.webp";
import img5 from "../assets/img/banner_01.webp";
import img6 from "../assets/img/banner_02.webp";
import img7 from "../assets/img/banner_08.webp";
import img8 from "../assets/img/banner_04.webp";
import img9 from "../assets/img/app_slider.webp";
import img10 from "../assets/img/parfum_sabun.webp";
import logo from "../assets/img/logo-white.svg";
import img11 from "../assets/img/perfume-copy.webp";
import img12 from "../assets/img/perfume_022.webp";
import img13 from "../assets/img/perfume_atelier_slider.webp";
import { useSelector } from "react-redux";

const HomeSlider = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 932);
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 932);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="home-slider-main">
      <div className="col-12">
        <Swiper
          loop={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
          className="mySwiper"
          clickable={true}
        >
          <SwiperSlide>
            <div className="slide-container custom-slide-adjust">
              <img src={isMobile ? img12 : img11} alt="img1" />
              <span className="slide-text">
                <br />
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_1_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <img src={img13} alt="img1"></img>
              <span className="slide-text ">
                <h4 className="text-start slide-by-text p-0 m-0 text-white fw-lg-bold fw-md-bold">
                  Yılbaşına Özel
                </h4>
                <h4>Hediye Setleri için Tıklayın!</h4>
                <br />
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a
                    href={
                      language == "en"
                        ? "/en/urunler/?kategori=Gift-Set"
                        : "/tr/urunler/?kategori=Hediye-Seti"
                    }
                  >
                    <button className="button-type-five" id="kesfet_2_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="slide-container">
              <img src={img9} alt="img1"></img>
              <span className="slide-text ">
                <h2 className="text-start text-white fw-lg-bold fw-md-bold slide-main-text">
                  <b>{data.mobil_ozel}</b>
                </h2>
                <h2 className="text-start slide-by-text text-white fw-lg-bold fw-md-bold">
                  {data.indirim_kodu_mobil}
                </h2>
                <h2 className="text-start slide-by-text text-white fw-lg-bold fw-md-bold">
                  mobil15
                </h2>
                <br />
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_1_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide> 
        
          <SwiperSlide>
            <div className="slide-container">
              <img src={img4} alt="img1"></img>
              <span className="slide-text ">
                <h2 className="text-start text-white fw-lg-bold fw-md-bold slide-main-text">
                  <b>{data.ilk_alisverisine_ozel}</b>
                </h2>
                <h2 className="text-start slide-by-text text-white fw-lg-bold fw-md-bold">
                  {data.indirim_kodu}
                </h2>
                <h2 className="text-start slide-by-text text-white fw-lg-bold fw-md-bold">
                  hosgeldin10
                </h2>
                <br />
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_1_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          */}

          <SwiperSlide>
            <div className="slide-container custom-slide-adjust-2">
              <img src={img2} alt="img2"></img>
              <span className="slide-text ">
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-six" id="kesfet_3_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container custom-slide-adjust-3">
              <img src={img5} alt="img2"></img>
              <span className="slide-text ">
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-six" id="kesfet_3_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container custom-slide-adjust-4">
              <img src={img6} alt="img2"></img>
              <span className="slide-text ">
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-six" id="kesfet_3_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container custom-slide-adjust-5">
              <img src={img7} alt="img2"></img>
              <span className="slide-text ">
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-six" id="kesfet_3_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default HomeSlider;
